import React from 'react';
import { Plus, Filter } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { RentNoticeList } from '../components/accounting/RentNoticeList';
import { RentNoticeGenerator } from '../components/accounting/RentNoticeGenerator';
import { useRentNoticeStore } from '../store/useRentNoticeStore';

export function RentNotices() {
  const { t } = useTranslation();
  const { setFilters } = useRentNoticeStore();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">
          {t('accounting.rentNotice.title')}
        </h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <Plus className="h-5 w-5 mr-2" />
          {t('accounting.rentNotice.create')}
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3">
          <RentNoticeList />
        </div>
        
        <div className="space-y-6">
          <RentNoticeGenerator />
          
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center gap-2 mb-4">
              <Filter className="h-5 w-5 text-gray-400" />
              <h3 className="text-lg font-medium text-gray-900">
                {t('common.filters')}
              </h3>
            </div>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('accounting.rentNotice.status')}
                </label>
                <select
                  onChange={(e) => setFilters({ status: e.target.value as any })}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="">{t('common.all')}</option>
                  <option value="draft">{t('accounting.rentNotice.status.draft')}</option>
                  <option value="sent">{t('accounting.rentNotice.status.sent')}</option>
                  <option value="paid">{t('accounting.rentNotice.status.paid')}</option>
                  <option value="late">{t('accounting.rentNotice.status.late')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}