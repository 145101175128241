import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateLocale } from '../../utils/date';
import { formatCurrency } from '../../utils/formatters';
import { useRentNoticeStore } from '../../store/useRentNoticeStore';
import type { RentNotice } from '../../types/accounting';

const statusColors = {
  draft: 'bg-gray-100 text-gray-800',
  sent: 'bg-blue-100 text-blue-800',
  paid: 'bg-green-100 text-green-800',
  late: 'bg-red-100 text-red-800',
};

export function RentNoticeList() {
  const { t } = useTranslation();
  const { filteredNotices, updateNotice, sendNotice } = useRentNoticeStore();

  const handleSendNotice = (notice: RentNotice) => {
    sendNotice(notice.id);
    updateNotice(notice.id, { 
      status: 'sent',
      sentAt: new Date(),
    });
  };

  return (
    <div className="bg-white shadow-sm rounded-lg">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('accounting.rentNotice.date')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('accounting.rentNotice.tenant')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('accounting.rentNotice.amount')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('accounting.rentNotice.dueDate')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('accounting.rentNotice.status')}
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('common.actions')}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredNotices.map((notice) => (
              <tr key={notice.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatDateLocale(notice.date)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {/* Ajouter le nom du locataire via useTenantStore */}
                  Locataire {notice.tenantId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatCurrency(notice.totalAmount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatDateLocale(notice.dueDate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColors[notice.status]}`}>
                    {t(`accounting.rentNotice.status.${notice.status}`)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  {notice.status === 'draft' && (
                    <button
                      onClick={() => handleSendNotice(notice)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      {t('accounting.rentNotice.send')}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}