import { create } from 'zustand';
import type { ReportData } from '../types';

interface ReportState {
  reports: ReportData[];
  selectedPeriod: 'daily' | 'weekly' | 'monthly' | 'yearly';
  setReports: (reports: ReportData[]) => void;
  setSelectedPeriod: (period: ReportState['selectedPeriod']) => void;
}

export const useReportStore = create<ReportState>((set) => ({
  reports: [],
  selectedPeriod: 'monthly',
  setReports: (reports) => set({ reports }),
  setSelectedPeriod: (selectedPeriod) => set({ selectedPeriod }),
}));