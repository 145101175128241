import { create } from 'zustand';
import type { MaintenanceRequest, MaintenanceSchedule } from '../types/maintenance';

interface MaintenanceState {
  requests: MaintenanceRequest[];
  schedules: MaintenanceSchedule[];
  filteredRequests: MaintenanceRequest[];
  filters: {
    status?: MaintenanceRequest['status'];
    priority?: MaintenanceRequest['priority'];
    category?: MaintenanceRequest['category'];
    dateRange?: {
      start: Date;
      end: Date;
    };
  };
  setRequests: (requests: MaintenanceRequest[]) => void;
  setSchedules: (schedules: MaintenanceSchedule[]) => void;
  setFilters: (filters: MaintenanceState['filters']) => void;
  addRequest: (request: MaintenanceRequest) => void;
  updateRequest: (id: string, updates: Partial<MaintenanceRequest>) => void;
}

const generateReference = () => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  return `MDT-${year}${month}-${random}`;
};

export const useMaintenanceStore = create<MaintenanceState>((set) => ({
  requests: [],
  schedules: [],
  filteredRequests: [],
  filters: {},

  setRequests: (requests) => 
    set({ 
      requests,
      filteredRequests: requests,
    }),

  setSchedules: (schedules) => set({ schedules }),

  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredRequests = state.requests.filter((request) => {
        const statusMatch = !filters.status || request.status === filters.status;
        const priorityMatch = !filters.priority || request.priority === filters.priority;
        const categoryMatch = !filters.category || request.category === filters.category;
        const dateMatch = !filters.dateRange || (
          new Date(request.createdAt) >= filters.dateRange.start &&
          new Date(request.createdAt) <= filters.dateRange.end
        );
        return statusMatch && priorityMatch && categoryMatch && dateMatch;
      });
      return { filters, filteredRequests };
    }),

  addRequest: (request) =>
    set((state) => {
      const newRequest = {
        ...request,
        reference: generateReference(),
      };
      return {
        requests: [...state.requests, newRequest],
        filteredRequests: [...state.filteredRequests, newRequest],
      };
    }),

  updateRequest: (id, updates) =>
    set((state) => {
      const requests = state.requests.map((r) =>
        r.id === id ? { ...r, ...updates, updatedAt: new Date() } : r
      );
      return {
        requests,
        filteredRequests: requests,
      };
    }),
}));