import { create } from 'zustand';
import type { Contract, Amendment, KeyDate, ContractTemplate } from '../types/contract';

interface ContractState {
  contracts: Contract[];
  templates: ContractTemplate[];
  filteredContracts: Contract[];
  filters: {
    type?: Contract['type'];
    status?: Contract['status'];
    propertyId?: string;
    tenantId?: string;
  };
  setContracts: (contracts: Contract[]) => void;
  setTemplates: (templates: ContractTemplate[]) => void;
  setFilters: (filters: ContractState['filters']) => void;
  addContract: (contract: Contract) => void;
  updateContract: (id: string, contract: Partial<Contract>) => void;
  addAmendment: (contractId: string, amendment: Amendment) => void;
  updateAmendment: (contractId: string, amendmentId: string, amendment: Partial<Amendment>) => void;
  addKeyDate: (contractId: string, keyDate: KeyDate) => void;
  updateKeyDate: (contractId: string, keyDateId: string, keyDate: Partial<KeyDate>) => void;
  generateContract: (templateId: string, data: any) => Promise<Contract>;
}

export const useContractStore = create<ContractState>((set, get) => ({
  contracts: [],
  templates: [],
  filteredContracts: [],
  filters: {},
  setContracts: (contracts) => 
    set({ 
      contracts,
      filteredContracts: contracts,
    }),
  setTemplates: (templates) => set({ templates }),
  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredContracts = state.contracts.filter((contract) => {
        const typeMatch = !filters.type || contract.type === filters.type;
        const statusMatch = !filters.status || contract.status === filters.status;
        const propertyMatch = !filters.propertyId || contract.propertyId === filters.propertyId;
        const tenantMatch = !filters.tenantId || contract.parties.tenantId === filters.tenantId;
        return typeMatch && statusMatch && propertyMatch && tenantMatch;
      });
      return { filters, filteredContracts };
    }),
  addContract: (contract) =>
    set((state) => ({
      contracts: [...state.contracts, contract],
      filteredContracts: [...state.filteredContracts, contract],
    })),
  updateContract: (id, updates) =>
    set((state) => {
      const contracts = state.contracts.map((c) =>
        c.id === id ? { ...c, ...updates, updatedAt: new Date() } : c
      );
      return {
        contracts,
        filteredContracts: contracts,
      };
    }),
  addAmendment: (contractId, amendment) =>
    set((state) => {
      const contracts = state.contracts.map((c) =>
        c.id === contractId
          ? {
              ...c,
              amendments: [...c.amendments, amendment],
              updatedAt: new Date(),
              version: c.version + 1,
            }
          : c
      );
      return {
        contracts,
        filteredContracts: contracts,
      };
    }),
  updateAmendment: (contractId, amendmentId, updates) =>
    set((state) => {
      const contracts = state.contracts.map((c) =>
        c.id === contractId
          ? {
              ...c,
              amendments: c.amendments.map((a) =>
                a.id === amendmentId ? { ...a, ...updates } : a
              ),
              updatedAt: new Date(),
            }
          : c
      );
      return {
        contracts,
        filteredContracts: contracts,
      };
    }),
  addKeyDate: (contractId, keyDate) =>
    set((state) => {
      const contracts = state.contracts.map((c) =>
        c.id === contractId
          ? {
              ...c,
              keyDates: [...c.keyDates, keyDate],
              updatedAt: new Date(),
            }
          : c
      );
      return {
        contracts,
        filteredContracts: contracts,
      };
    }),
  updateKeyDate: (contractId, keyDateId, updates) =>
    set((state) => {
      const contracts = state.contracts.map((c) =>
        c.id === contractId
          ? {
              ...c,
              keyDates: c.keyDates.map((k) =>
                k.id === keyDateId ? { ...k, ...updates } : k
              ),
              updatedAt: new Date(),
            }
          : c
      );
      return {
        contracts,
        filteredContracts: contracts,
      };
    }),
  generateContract: async (templateId: string, data: any) => {
    const template = get().templates.find((t) => t.id === templateId);
    if (!template) {
      throw new Error('Template not found');
    }

    // Logique de génération du contrat à partir du template
    const contract: Contract = {
      id: crypto.randomUUID(),
      type: template.type,
      title: data.title,
      status: 'draft',
      startDate: new Date(data.startDate),
      parties: data.parties,
      propertyId: data.propertyId,
      documents: [],
      amendments: [],
      keyDates: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      version: 1,
    };

    get().addContract(contract);
    return contract;
  },
}));