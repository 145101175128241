import React from 'react';
import { Calendar, Clock, Wrench } from 'lucide-react';
import { useMaintenanceStore } from '../../store/useMaintenanceStore';
import { formatDateLocale } from '../../utils/date';
import type { MaintenanceSchedule } from '../../types/maintenance';

interface MaintenanceScheduleCalendarProps {
  onScheduleClick: (schedule: MaintenanceSchedule) => void;
}

export function MaintenanceScheduleCalendar({ onScheduleClick }: MaintenanceScheduleCalendarProps) {
  const { schedules } = useMaintenanceStore();

  const sortedSchedules = [...schedules].sort((a, b) => 
    new Date(a.nextDate).getTime() - new Date(b.nextDate).getTime()
  );

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900">Planning des interventions</h3>
      </div>

      <div className="space-y-4">
        {sortedSchedules.map((schedule) => (
          <div
            key={schedule.id}
            onClick={() => onScheduleClick(schedule)}
            className="flex items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
          >
            <div className="flex-shrink-0">
              {schedule.type === 'recurring' ? (
                <Clock className="h-6 w-6 text-blue-600" />
              ) : (
                <Wrench className="h-6 w-6 text-green-600" />
              )}
            </div>
            
            <div className="ml-4 flex-1">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">{schedule.title}</p>
                  <p className="text-sm text-gray-500">{schedule.description}</p>
                </div>
                <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                  schedule.status === 'completed' ? 'bg-green-100 text-green-800' :
                  schedule.status === 'overdue' ? 'bg-red-100 text-red-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {schedule.status}
                </span>
              </div>
              
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <Calendar className="h-4 w-4 mr-1" />
                <span>{formatDateLocale(schedule.nextDate)}</span>
                {schedule.frequency && (
                  <span className="ml-4 text-xs bg-gray-200 px-2 py-1 rounded">
                    {schedule.frequency}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}