import React from 'react';
import { Filter, Calendar } from 'lucide-react';
import { useMaintenanceStore } from '../../store/useMaintenanceStore';

export function MaintenanceFilters() {
  const { setFilters } = useMaintenanceStore();

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
      <div className="flex items-center gap-2">
        <Filter className="h-5 w-5 text-gray-400" />
        <h3 className="font-medium text-gray-900">Filtres</h3>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Statut
          </label>
          <select
            onChange={(e) => setFilters({ status: e.target.value as any })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Tous</option>
            <option value="new">Nouveau</option>
            <option value="scheduled">Planifié</option>
            <option value="in_progress">En cours</option>
            <option value="completed">Terminé</option>
            <option value="cancelled">Annulé</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Priorité
          </label>
          <select
            onChange={(e) => setFilters({ priority: e.target.value as any })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Toutes</option>
            <option value="urgent">Urgente</option>
            <option value="high">Haute</option>
            <option value="medium">Moyenne</option>
            <option value="low">Basse</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Catégorie
          </label>
          <select
            onChange={(e) => setFilters({ category: e.target.value as any })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Toutes</option>
            <option value="plumbing">Plomberie</option>
            <option value="electrical">Électricité</option>
            <option value="heating">Chauffage</option>
            <option value="structural">Structure</option>
            <option value="other">Autre</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Période
          </label>
          <div className="mt-1 flex items-center gap-2">
            <div className="relative flex-1">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="date"
                onChange={(e) => setFilters({
                  dateRange: {
                    start: new Date(e.target.value),
                    end: new Date(),
                  },
                })}
                className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}