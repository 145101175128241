import React from 'react';
import { CompanySettingsForm } from '../components/settings/CompanySettingsForm';
import { NotificationSettingsForm } from '../components/settings/NotificationSettingsForm';
import { SystemSettingsForm } from '../components/settings/SystemSettingsForm';

export function Settings() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Save Changes
        </button>
      </div>

      <div className="space-y-6">
        <CompanySettingsForm />
        <NotificationSettingsForm />
        <SystemSettingsForm />
      </div>
    </div>
  );
}