import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'lucide-react';
import { useRentNoticeStore } from '../../store/useRentNoticeStore';
import { useLeaseStore } from '../../store/useLeaseStore';

export function RentNoticeGenerator() {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const { generateNotice } = useRentNoticeStore();
  const { leases } = useLeaseStore();

  const handleGenerateNotices = () => {
    // Générer les avis d'échéance pour tous les baux actifs
    leases
      .filter(lease => lease.status === 'active')
      .forEach(lease => {
        generateNotice(lease.id, selectedMonth);
      });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-medium text-gray-900 mb-4">
        {t('accounting.rentNotice.generate')}
      </h3>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {t('accounting.rentNotice.selectMonth')}
          </label>
          <div className="relative">
            <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="month"
              value={selectedMonth.toISOString().slice(0, 7)}
              onChange={(e) => setSelectedMonth(new Date(e.target.value))}
              className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <button
          onClick={handleGenerateNotices}
          className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {t('accounting.rentNotice.generateButton')}
        </button>
      </div>
    </div>
  );
}