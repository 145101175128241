import React from 'react';
import { useReportStore } from '../../store/useReportStore';

const periods = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
] as const;

export function PeriodSelector() {
  const { selectedPeriod, setSelectedPeriod } = useReportStore();

  return (
    <div className="flex space-x-2">
      {periods.map(({ label, value }) => (
        <button
          key={value}
          onClick={() => setSelectedPeriod(value)}
          className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
            selectedPeriod === value
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-700 hover:bg-gray-50'
          }`}
        >
          {label}
        </button>
      ))}
    </div>
  );
}