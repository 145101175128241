import { create } from 'zustand';
import type { Property } from '../types';

interface PropertyState {
  properties: Property[];
  filteredProperties: Property[];
  filters: {
    type?: string;
    status?: string;
    minPrice?: number;
    maxPrice?: number;
  };
  setProperties: (properties: Property[]) => void;
  setFilters: (filters: PropertyState['filters']) => void;
  addProperty: (property: Property) => void;
  updateProperty: (id: string, property: Partial<Property>) => void;
}

export const usePropertyStore = create<PropertyState>((set) => ({
  properties: [],
  filteredProperties: [],
  filters: {},
  setProperties: (properties) => 
    set({ 
      properties,
      filteredProperties: properties,
    }),
  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredProperties = state.properties.filter((property) => {
        const typeMatch = !filters.type || property.type === filters.type;
        const statusMatch = !filters.status || property.status === filters.status;
        const priceMatch = (!filters.minPrice || property.price >= filters.minPrice) &&
                          (!filters.maxPrice || property.price <= filters.maxPrice);
        return typeMatch && statusMatch && priceMatch;
      });
      return { filters, filteredProperties };
    }),
  addProperty: (property) =>
    set((state) => ({
      properties: [...state.properties, property],
      filteredProperties: [...state.filteredProperties, property],
    })),
  updateProperty: (id, updates) =>
    set((state) => {
      const properties = state.properties.map((p) =>
        p.id === id ? { ...p, ...updates } : p
      );
      return {
        properties,
        filteredProperties: properties,
      };
    }),
}));