import React from 'react';
import { Camera, Plus, ChevronRight } from 'lucide-react';
import type { Room, RoomElement } from '../../types/inspection';
import { InspectionCamera } from './InspectionCamera';

interface RoomInspectionProps {
  room: Room;
  onUpdate: (elementId: string, updates: Partial<RoomElement>) => void;
}

export function RoomInspection({ room, onUpdate }: RoomInspectionProps) {
  const [showCamera, setShowCamera] = React.useState(false);
  const [selectedElement, setSelectedElement] = React.useState<string | null>(null);

  const handlePhotoClick = (elementId: string) => {
    setSelectedElement(elementId);
    setShowCamera(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900">{room.name}</h3>
        <button className="text-blue-600">
          <Plus className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-4">
        {room.elements.map((element) => (
          <div
            key={element.id}
            className="bg-white rounded-lg border border-gray-200 p-4"
          >
            <div className="flex items-center justify-between mb-4">
              <div>
                <h4 className="text-sm font-medium text-gray-900">
                  {element.name}
                </h4>
                <p className="text-sm text-gray-500">
                  {element.category}
                </p>
              </div>
              <button
                onClick={() => handlePhotoClick(element.id)}
                className="p-2 text-gray-400 hover:text-gray-500"
              >
                <Camera className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  État
                </label>
                <select
                  value={element.condition}
                  onChange={(e) => onUpdate(element.id, { condition: e.target.value as RoomElement['condition'] })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="new">Neuf</option>
                  <option value="good">Bon état</option>
                  <option value="average">État moyen</option>
                  <option value="poor">Mauvais état</option>
                  <option value="damaged">Endommagé</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Commentaires
                </label>
                <textarea
                  value={element.comments}
                  onChange={(e) => onUpdate(element.id, { comments: e.target.value })}
                  rows={2}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              {element.photos.length > 0 && (
                <div className="flex gap-2 overflow-x-auto py-2">
                  {element.photos.map((photo) => (
                    <img
                      key={photo.id}
                      src={photo.thumbnail}
                      alt=""
                      className="h-20 w-20 object-cover rounded-lg"
                    />
                  ))}
                </div>
              )}

              {element.comparison && (
                <div className="mt-4 p-4 bg-yellow-50 rounded-lg">
                  <p className="text-sm font-medium text-yellow-800">
                    Comparaison avec l'état des lieux d'entrée
                  </p>
                  <p className="text-sm text-yellow-600 mt-1">
                    État précédent : {element.comparison.previousCondition}
                  </p>
                  {element.comparison.differences.map((diff, index) => (
                    <p key={index} className="text-sm text-yellow-600 mt-1">
                      • {diff}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {showCamera && selectedElement && (
        <InspectionCamera
          roomId={room.id}
          elementId={selectedElement}
          onClose={() => {
            setShowCamera(false);
            setSelectedElement(null);
          }}
        />
      )}
    </div>
  );
}