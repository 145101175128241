import React from 'react';
import { FileText, Calendar, User, Building2 } from 'lucide-react';
import { format } from 'date-fns';
import type { Document } from '../../types';
import { usePropertyStore } from '../../store/usePropertyStore';
import { useTenantStore } from '../../store/useTenantStore';

interface DocumentCardProps {
  document: Document;
  onClick: (document: Document) => void;
}

export function DocumentCard({ document, onClick }: DocumentCardProps) {
  const property = usePropertyStore(
    (state) => document.relatedTo?.type === 'property' 
      ? state.properties.find(p => p.id === document.relatedTo?.id)
      : null
  );

  const tenant = useTenantStore(
    (state) => document.relatedTo?.type === 'tenant'
      ? state.tenants.find(t => t.id === document.relatedTo?.id)
      : null
  );

  const statusColors = {
    draft: 'bg-gray-100 text-gray-800',
    pending: 'bg-yellow-100 text-yellow-800',
    signed: 'bg-green-100 text-green-800',
    expired: 'bg-red-100 text-red-800',
  };

  const formatFileSize = (bytes: number) => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    return `${Math.round(size)} ${units[unitIndex]}`;
  };

  return (
    <div 
      onClick={() => onClick(document)}
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer p-4"
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center">
          <div className="bg-blue-100 rounded-full p-3">
            <FileText className="h-6 w-6 text-blue-600" />
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-semibold text-gray-900">
              {document.title}
            </h3>
            <p className="text-sm text-gray-600">
              {document.type.charAt(0).toUpperCase() + document.type.slice(1)}
            </p>
          </div>
        </div>
        <span className={`px-3 py-1 rounded-full text-sm font-medium ${statusColors[document.status]}`}>
          {document.status.toUpperCase()}
        </span>
      </div>

      <div className="space-y-2 text-sm text-gray-600">
        {document.relatedTo && (
          <div className="flex items-center">
            {document.relatedTo.type === 'property' ? (
              <>
                <Building2 className="w-4 h-4 mr-2" />
                <span>{property?.address.street || 'Property not found'}</span>
              </>
            ) : (
              <>
                <User className="w-4 h-4 mr-2" />
                <span>
                  {tenant ? `${tenant.firstName} ${tenant.lastName}` : 'Tenant not found'}
                </span>
              </>
            )}
          </div>
        )}
        <div className="flex items-center">
          <Calendar className="w-4 h-4 mr-2" />
          <span>
            Updated {format(new Date(document.updatedAt), 'PP')}
          </span>
        </div>
        <div className="text-sm text-gray-500">
          {formatFileSize(document.size)}
        </div>
      </div>
    </div>
  );
}