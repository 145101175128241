import { Routes, Route } from 'react-router-dom';
import { Layout } from '@/components/layout/Layout';
import { Landing } from '@/pages/Landing';
import { Dashboard } from '@/pages/Dashboard';
import { Properties } from '@/pages/Properties';
import { Tenants } from '@/pages/Tenants';
import { Documents } from '@/pages/Documents';
import { Reports } from '@/pages/Reports';
import { Settings } from '@/pages/Settings';
import { RentNotices } from '@/pages/RentNotices';
import { Contracts } from '@/pages/Contracts';
import { CondoIndex } from '@/pages/condo';
import { Assemblies } from '@/pages/condo/Assemblies';
import { Expenses } from '@/pages/condo/Expenses';
import { Maintenance } from '@/pages/Maintenance';
import { InspectionForm } from '@/pages/inspections/InspectionForm';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/*" element={<Layout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="properties" element={<Properties />} />
        <Route path="tenants">
          <Route index element={<Tenants />} />
          <Route path="rent-notices" element={<RentNotices />} />
          <Route path="contracts" element={<Contracts />} />
        </Route>
        <Route path="condo">
          <Route index element={<CondoIndex />} />
          <Route path="assemblies" element={<Assemblies />} />
          <Route path="expenses" element={<Expenses />} />
        </Route>
        <Route path="maintenance/*" element={<Maintenance />} />
        <Route path="inspections">
          <Route path="entry" element={<InspectionForm />} />
          <Route path="exit" element={<InspectionForm />} />
        </Route>
        <Route path="documents" element={<Documents />} />
        <Route path="reports" element={<Reports />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
}