import React from 'react';
import { Download } from 'lucide-react';
import { ReportCard } from '../components/reports/ReportCard';
import { PeriodSelector } from '../components/reports/PeriodSelector';
import { useReportStore } from '../store/useReportStore';
import type { ReportData } from '../types';

// Mock data for initial development
const mockReports: ReportData[] = [
  {
    id: '1',
    type: 'revenue',
    title: 'Revenue Overview',
    period: 'monthly',
    lastUpdated: new Date(),
    data: [
      { name: 'Jan', value: 45000 },
      { name: 'Feb', value: 52000 },
      { name: 'Mar', value: 48000 },
      { name: 'Apr', value: 61000 },
      { name: 'May', value: 55000 },
      { name: 'Jun', value: 67000 },
    ],
  },
  {
    id: '2',
    type: 'occupancy',
    title: 'Occupancy Rate',
    period: 'monthly',
    lastUpdated: new Date(),
    data: [
      { name: 'Jan', value: 85 },
      { name: 'Feb', value: 88 },
      { name: 'Mar', value: 87 },
      { name: 'Apr', value: 92 },
      { name: 'May', value: 90 },
      { name: 'Jun', value: 94 },
    ],
  },
  {
    id: '3',
    type: 'maintenance',
    title: 'Maintenance Costs',
    period: 'monthly',
    lastUpdated: new Date(),
    data: [
      { name: 'Jan', value: 5200 },
      { name: 'Feb', value: 4800 },
      { name: 'Mar', value: 6100 },
      { name: 'Apr', value: 4500 },
      { name: 'May', value: 5800 },
      { name: 'Jun', value: 5100 },
    ],
  },
  {
    id: '4',
    type: 'tenant',
    title: 'Tenant Turnover',
    period: 'monthly',
    lastUpdated: new Date(),
    data: [
      { name: 'Jan', value: 2 },
      { name: 'Feb', value: 1 },
      { name: 'Mar', value: 3 },
      { name: 'Apr', value: 0 },
      { name: 'May', value: 2 },
      { name: 'Jun', value: 1 },
    ],
  },
];

export function Reports() {
  const { reports, setReports } = useReportStore();

  // Initialize with mock data
  React.useEffect(() => {
    setReports(mockReports);
  }, [setReports]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>
        <div className="flex items-center space-x-4">
          <PeriodSelector />
          <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <Download className="h-5 w-5 mr-2" />
            Export
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {reports.map((report) => (
          <ReportCard key={report.id} report={report} />
        ))}
      </div>
    </div>
  );
}