import { create } from 'zustand';
import type { Lease, RentPayment } from '../types/rental';

interface LeaseState {
  leases: Lease[];
  filteredLeases: Lease[];
  filters: {
    status?: Lease['status'];
    propertyId?: string;
    tenantId?: string;
  };
  setLeases: (leases: Lease[]) => void;
  setFilters: (filters: LeaseState['filters']) => void;
  addLease: (lease: Lease) => void;
  updateLease: (id: string, lease: Partial<Lease>) => void;
  addPayment: (leaseId: string, payment: RentPayment) => void;
}

export const useLeaseStore = create<LeaseState>((set) => ({
  leases: [],
  filteredLeases: [],
  filters: {},
  setLeases: (leases) => 
    set({ 
      leases,
      filteredLeases: leases,
    }),
  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredLeases = state.leases.filter((lease) => {
        const statusMatch = !filters.status || lease.status === filters.status;
        const propertyMatch = !filters.propertyId || lease.propertyId === filters.propertyId;
        const tenantMatch = !filters.tenantId || lease.tenantId === filters.tenantId;
        return statusMatch && propertyMatch && tenantMatch;
      });
      return { filters, filteredLeases };
    }),
  addLease: (lease) =>
    set((state) => ({
      leases: [...state.leases, lease],
      filteredLeases: [...state.filteredLeases, lease],
    })),
  updateLease: (id, updates) =>
    set((state) => {
      const leases = state.leases.map((l) =>
        l.id === id ? { ...l, ...updates } : l
      );
      return {
        leases,
        filteredLeases: leases,
      };
    }),
  addPayment: (leaseId, payment) =>
    set((state) => {
      const leases = state.leases.map((lease) =>
        lease.id === leaseId
          ? {
              ...lease,
              paymentHistory: [...lease.paymentHistory, payment],
            }
          : lease
      );
      return {
        leases,
        filteredLeases: leases,
      };
    }),
}));