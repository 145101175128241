import { create } from 'zustand';
import type { RentNotice } from '../types/accounting';

interface RentNoticeState {
  notices: RentNotice[];
  filteredNotices: RentNotice[];
  filters: {
    status?: RentNotice['status'];
    tenantId?: string;
    propertyId?: string;
    month?: number;
    year?: number;
  };
  setNotices: (notices: RentNotice[]) => void;
  setFilters: (filters: RentNoticeState['filters']) => void;
  addNotice: (notice: RentNotice) => void;
  updateNotice: (id: string, notice: Partial<RentNotice>) => void;
  generateNotice: (leaseId: string, date: Date) => void;
  sendNotice: (id: string) => void;
}

export const useRentNoticeStore = create<RentNoticeState>((set) => ({
  notices: [],
  filteredNotices: [],
  filters: {},
  setNotices: (notices) => 
    set({ 
      notices,
      filteredNotices: notices,
    }),
  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredNotices = state.notices.filter((notice) => {
        const statusMatch = !filters.status || notice.status === filters.status;
        const tenantMatch = !filters.tenantId || notice.tenantId === filters.tenantId;
        const propertyMatch = !filters.propertyId || notice.propertyId === filters.propertyId;
        const date = new Date(notice.date);
        const monthMatch = !filters.month || date.getMonth() === filters.month;
        const yearMatch = !filters.year || date.getFullYear() === filters.year;
        return statusMatch && tenantMatch && propertyMatch && monthMatch && yearMatch;
      });
      return { filters, filteredNotices };
    }),
  addNotice: (notice) =>
    set((state) => ({
      notices: [...state.notices, notice],
      filteredNotices: [...state.filteredNotices, notice],
    })),
  updateNotice: (id, updates) =>
    set((state) => {
      const notices = state.notices.map((n) =>
        n.id === id ? { ...n, ...updates } : n
      );
      return {
        notices,
        filteredNotices: notices,
      };
    }),
  generateNotice: (leaseId: string, date: Date) => {
    // Logique de génération d'un nouvel avis d'échéance
    console.log('Generating notice for lease:', leaseId, 'date:', date);
  },
  sendNotice: (id: string) => {
    // Logique d'envoi de l'avis d'échéance
    console.log('Sending notice:', id);
  },
}));