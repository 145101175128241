import { create } from 'zustand';
import type { Tenant } from '../types';

interface TenantState {
  tenants: Tenant[];
  filteredTenants: Tenant[];
  filters: {
    status?: 'active' | 'expired';
    propertyId?: string;
  };
  setTenants: (tenants: Tenant[]) => void;
  setFilters: (filters: TenantState['filters']) => void;
  addTenant: (tenant: Tenant) => void;
  updateTenant: (id: string, tenant: Partial<Tenant>) => void;
}

export const useTenantStore = create<TenantState>((set) => ({
  tenants: [],
  filteredTenants: [],
  filters: {},
  setTenants: (tenants) => 
    set({ 
      tenants,
      filteredTenants: tenants,
    }),
  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredTenants = state.tenants.filter((tenant) => {
        const now = new Date();
        const isActive = new Date(tenant.leaseEnd) > now;
        const statusMatch = !filters.status || 
          (filters.status === 'active' ? isActive : !isActive);
        const propertyMatch = !filters.propertyId || 
          tenant.propertyId === filters.propertyId;
        return statusMatch && propertyMatch;
      });
      return { filters, filteredTenants };
    }),
  addTenant: (tenant) =>
    set((state) => ({
      tenants: [...state.tenants, tenant],
      filteredTenants: [...state.filteredTenants, tenant],
    })),
  updateTenant: (id, updates) =>
    set((state) => {
      const tenants = state.tenants.map((t) =>
        t.id === id ? { ...t, ...updates } : t
      );
      return {
        tenants,
        filteredTenants: tenants,
      };
    }),
}));