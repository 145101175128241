import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { DocumentCard } from '../components/documents/DocumentCard';
import { DocumentFilters } from '../components/documents/DocumentFilters';
import { useDocumentStore } from '../store/useDocumentStore';
import type { Document } from '../types';

// Mock data for initial development
const mockDocuments: Document[] = [
  {
    id: '1',
    title: 'Lease Agreement - Apartment 101',
    type: 'lease',
    category: 'property',
    status: 'signed',
    createdAt: new Date('2024-01-15'),
    updatedAt: new Date('2024-01-15'),
    size: 2457600, // 2.4MB
    relatedTo: {
      type: 'property',
      id: '1',
    },
  },
  {
    id: '2',
    title: 'Monthly Rent Invoice - March 2024',
    type: 'invoice',
    category: 'financial',
    status: 'pending',
    createdAt: new Date('2024-03-01'),
    updatedAt: new Date('2024-03-01'),
    size: 1048576, // 1MB
    relatedTo: {
      type: 'tenant',
      id: '1',
    },
  },
];

export function Documents() {
  const { filteredDocuments, setDocuments, setFilters } = useDocumentStore();
  const [searchTerm, setSearchTerm] = useState('');

  // Initialize with mock data
  React.useEffect(() => {
    setDocuments(mockDocuments);
  }, [setDocuments]);

  const handleDocumentClick = (document: Document) => {
    // Handle document selection
    console.log('Selected document:', document);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Documents</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <Plus className="h-5 w-5 mr-2" />
          Add Document
        </button>
      </div>

      <div className="flex gap-6">
        <div className="w-64 flex-shrink-0">
          <DocumentFilters onFilterChange={setFilters} />
        </div>

        <div className="flex-1 space-y-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search documents..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredDocuments.map((document) => (
              <DocumentCard
                key={document.id}
                document={document}
                onClick={handleDocumentClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}