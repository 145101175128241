import React from 'react';
import { Building2, Euro, Maximize2, BedDouble, Bath, Car } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import type { Property } from '../../types';
import { formatCurrency } from '../../utils/formatters';

interface PropertyCardProps {
  property: Property;
  onClick: (property: Property) => void;
}

export function PropertyCard({ property, onClick }: PropertyCardProps) {
  const { t } = useTranslation();

  const statusColors = {
    available: 'bg-green-100 text-green-800',
    rented: 'bg-blue-100 text-blue-800',
    for_sale: 'bg-purple-100 text-purple-800',
    sold: 'bg-gray-100 text-gray-800',
  };

  const getStatusLabel = (status: string) => {
    return t(`properties.status.${status}`);
  };

  return (
    <div 
      onClick={() => onClick(property)}
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
    >
      <div className="relative">
        <img
          src={`https://source.unsplash.com/800x600/?apartment,house&random=${property.id}`}
          alt={`${property.address.street}`}
          className="w-full h-48 object-cover rounded-t-lg"
        />
        <span className={`absolute top-4 right-4 px-3 py-1 rounded-full text-sm font-medium ${statusColors[property.status]}`}>
          {getStatusLabel(property.status)}
        </span>
      </div>
      
      <div className="p-4">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{property.address.street}</h3>
            <p className="text-sm text-gray-600">{property.address.city}, {property.address.postalCode}</p>
          </div>
          <p className="text-xl font-bold text-blue-600">{formatCurrency(property.price)}</p>
        </div>

        <div className="grid grid-cols-3 gap-4 text-sm text-gray-600">
          <div className="flex items-center gap-1">
            <Maximize2 className="w-4 h-4" />
            <span>{property.features.size}m²</span>
          </div>
          <div className="flex items-center gap-1">
            <BedDouble className="w-4 h-4" />
            <span>{property.features.rooms} pièces</span>
          </div>
          <div className="flex items-center gap-1">
            <Bath className="w-4 h-4" />
            <span>{property.features.bathrooms} SdB</span>
          </div>
        </div>
      </div>
    </div>
  );
}