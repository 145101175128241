import React from 'react';
import { User, Calendar, Building2 } from 'lucide-react';
import type { Tenant } from '../../types';
import { usePropertyStore } from '../../store/usePropertyStore';
import { formatDateLocale } from '../../utils/date';

interface TenantCardProps {
  tenant: Tenant;
  onClick: (tenant: Tenant) => void;
}

export function TenantCard({ tenant, onClick }: TenantCardProps) {
  const property = usePropertyStore(
    (state) => state.properties.find((p) => p.id === tenant.propertyId)
  );

  const isActive = new Date(tenant.leaseEnd) > new Date();

  return (
    <div 
      onClick={() => onClick(tenant)}
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer p-4"
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center">
          <div className="bg-blue-100 rounded-full p-3">
            <User className="h-6 w-6 text-blue-600" />
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-semibold text-gray-900">
              {tenant.firstName} {tenant.lastName}
            </h3>
            <p className="text-sm text-gray-600">{tenant.email}</p>
          </div>
        </div>
        <span className={`px-3 py-1 rounded-full text-sm font-medium ${
          isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {isActive ? 'Actif' : 'Expiré'}
        </span>
      </div>

      <div className="space-y-2 text-sm text-gray-600">
        <div className="flex items-center">
          <Building2 className="w-4 h-4 mr-2" />
          <span>{property?.address.street || 'Propriété non trouvée'}</span>
        </div>
        <div className="flex items-center">
          <Calendar className="w-4 h-4 mr-2" />
          <span>
            {formatDateLocale(tenant.leaseStart)} - {formatDateLocale(tenant.leaseEnd)}
          </span>
        </div>
      </div>
    </div>
  );
}