import React from 'react';
import { Calendar, Bell } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { formatDateLocale } from '../../utils/date';
import type { KeyDate } from '../../types/contract';

interface KeyDatesListProps {
  keyDates: KeyDate[];
  onNotify: (keyDate: KeyDate) => void;
}

export function KeyDatesList({ keyDates, onNotify }: KeyDatesListProps) {
  const { t } = useTranslation();

  const sortedKeyDates = [...keyDates].sort((a, b) => 
    new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">Dates clés</h3>
      
      <div className="space-y-2">
        {sortedKeyDates.map((keyDate) => (
          <div
            key={keyDate.id}
            className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200"
          >
            <div className="flex items-center space-x-4">
              <Calendar className="h-5 w-5 text-gray-400" />
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {keyDate.description}
                </p>
                <p className="text-sm text-gray-500">
                  {formatDateLocale(keyDate.date)}
                </p>
              </div>
            </div>
            
            {!keyDate.notified && (
              <button
                onClick={() => onNotify(keyDate)}
                className="p-2 text-blue-600 hover:text-blue-800"
              >
                <Bell className="h-5 w-5" />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}