import React from 'react';
import { Building, ClipboardList, Calculator } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function CondoIndex() {
  const { t } = useTranslation();

  const modules = [
    {
      title: t('condo.assemblies'),
      icon: ClipboardList,
      path: '/condo/assemblies',
      description: 'Gérez les assemblées générales et extraordinaires',
    },
    {
      title: t('condo.expenses'),
      icon: Calculator,
      path: '/condo/expenses',
      description: 'Suivez les charges et leur répartition',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Building className="h-8 w-8 text-blue-600" />
        <h1 className="text-2xl font-semibold text-gray-900">
          {t('navigation.condo')}
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {modules.map((module) => (
          <Link
            key={module.path}
            to={module.path}
            className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex items-center gap-3 mb-4">
              <module.icon className="h-6 w-6 text-blue-600" />
              <h2 className="text-lg font-medium text-gray-900">{module.title}</h2>
            </div>
            <p className="text-gray-600">{module.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}