import { create } from 'zustand';
import type { Document } from '../types';

interface DocumentState {
  documents: Document[];
  filteredDocuments: Document[];
  filters: {
    type?: Document['type'];
    category?: Document['category'];
    status?: Document['status'];
  };
  setDocuments: (documents: Document[]) => void;
  setFilters: (filters: DocumentState['filters']) => void;
  addDocument: (document: Document) => void;
  updateDocument: (id: string, document: Partial<Document>) => void;
}

export const useDocumentStore = create<DocumentState>((set) => ({
  documents: [],
  filteredDocuments: [],
  filters: {},
  setDocuments: (documents) => 
    set({ 
      documents,
      filteredDocuments: documents,
    }),
  setFilters: (newFilters) =>
    set((state) => {
      const filters = { ...state.filters, ...newFilters };
      const filteredDocuments = state.documents.filter((document) => {
        const typeMatch = !filters.type || document.type === filters.type;
        const categoryMatch = !filters.category || document.category === filters.category;
        const statusMatch = !filters.status || document.status === filters.status;
        return typeMatch && categoryMatch && statusMatch;
      });
      return { filters, filteredDocuments };
    }),
  addDocument: (document) =>
    set((state) => ({
      documents: [...state.documents, document],
      filteredDocuments: [...state.filteredDocuments, document],
    })),
  updateDocument: (id, updates) =>
    set((state) => {
      const documents = state.documents.map((d) =>
        d.id === id ? { ...d, ...updates } : d
      );
      return {
        documents,
        filteredDocuments: documents,
      };
    }),
}));