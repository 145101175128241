import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  Home, 
  Building2, 
  Users, 
  FileText, 
  Settings,
  BarChart3,
  Building,
  Wrench,
  Camera
} from 'lucide-react';

const navigation = [
  { name: 'navigation.dashboard', icon: Home, path: '/dashboard' },
  { name: 'navigation.properties', icon: Building2, path: '/properties' },
  { name: 'navigation.tenants', icon: Users, path: '/tenants' },
  { name: 'navigation.condo', icon: Building, path: '/condo' },
  { name: 'navigation.maintenance', icon: Wrench, path: '/maintenance' },
  { name: 'navigation.inspections', icon: Camera, path: '/inspections/entry' },
  { name: 'navigation.documents', icon: FileText, path: '/documents' },
  { name: 'navigation.reports', icon: BarChart3, path: '/reports' },
  { name: 'navigation.settings', icon: Settings, path: '/settings' },
];

export function Sidebar() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-64 bg-white border-r border-gray-200">
      <div className="flex items-center justify-center h-16 px-4 border-b border-gray-200">
        <img
          src="https://cerenzia.dev/images/projets/a2g.png"
          alt="A2G Logo"
          className="h-8"
        />
      </div>
      
      <nav className="flex-1 px-2 py-4 space-y-1">
        {navigation.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              `flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                isActive
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-700 hover:bg-gray-50'
              }`
            }
          >
            <item.icon className="w-5 h-5 mr-3" />
            {t(item.name)}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}