import React from 'react';
import { Link } from 'react-router-dom';
import { Building2, Users, FileText, Settings, BarChart3, Shield } from 'lucide-react';

export function Landing() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <img
              src="https://cerenzia.dev/images/projets/a2g.png"
              alt="A2G Logo"
              className="h-12"
            />
            <Link
              to="/dashboard"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Démonstration de l'outil
            </Link>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main>
        {/* Hero */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Gestion immobilière</span>
              <span className="block text-blue-600">simplifiée et efficace</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Une solution complète pour la gestion de vos biens immobiliers, de vos locataires et de vos copropriétés.
            </p>
          </div>

          {/* Preview */}
          <div className="mt-16">
            <div className="w-full max-w-6xl mx-auto">
              <div className="relative w-full h-0 pb-[56.25%] rounded-lg shadow-2xl overflow-hidden">
                <img
                  src="https://images.unsplash.com/photo-1551434678-e076c223a692?auto=format&fit=crop&w=2850&q=80"
                  alt="Dashboard Preview"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">
                Toutes les fonctionnalités dont vous avez besoin
              </h2>
            </div>

            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {/* Features cards */}
                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                          <Building2 className="h-6 w-6 text-white" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900">Gestion des biens</h3>
                      <p className="mt-5 text-base text-gray-500">
                        Gérez facilement vos propriétés, suivez les locations et optimisez votre patrimoine immobilier.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                          <Users className="h-6 w-6 text-white" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900">Gestion locative</h3>
                      <p className="mt-5 text-base text-gray-500">
                        Suivez vos locataires, les paiements et gérez les documents administratifs en toute simplicité.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                          <FileText className="h-6 w-6 text-white" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900">Documents automatisés</h3>
                      <p className="mt-5 text-base text-gray-500">
                        Générez automatiquement vos contrats, quittances et autres documents administratifs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                          <Settings className="h-6 w-6 text-white" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900">Maintenance simplifiée</h3>
                      <p className="mt-5 text-base text-gray-500">
                        Gérez les demandes d'intervention et suivez les travaux de maintenance en temps réel.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                          <BarChart3 className="h-6 w-6 text-white" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900">Tableaux de bord</h3>
                      <p className="mt-5 text-base text-gray-500">
                        Visualisez vos performances et prenez des décisions éclairées grâce à des rapports détaillés.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                          <Shield className="h-6 w-6 text-white" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900">Sécurité garantie</h3>
                      <p className="mt-5 text-base text-gray-500">
                        Vos données sont sécurisées et conformes aux normes RGPD en vigueur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center space-y-4">
            <img
              src="https://cerenzia.dev/images/projets/a2g.png"
              alt="A2G Logo"
              className="h-10 mx-auto mb-4"
            />
            <p className="text-base text-gray-500">© 2024 A2G. Tous droits réservés.</p>
            <p className="text-sm text-gray-500">
              Site créé avec ❤️ par{' '}
              <a 
                href="https://cerenzia.dev" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                Mathieu Cerenzia
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}