import { create } from 'zustand';
import type { CompanySettings, NotificationSettings, SystemSettings } from '../types/settings';

interface SettingsState {
  company: CompanySettings;
  notifications: NotificationSettings;
  system: SystemSettings;
  setCompanySettings: (settings: Partial<CompanySettings>) => void;
  setNotificationSettings: (settings: Partial<NotificationSettings>) => void;
  setSystemSettings: (settings: Partial<SystemSettings>) => void;
}

const defaultSettings: SettingsState = {
  company: {
    name: 'A2G Real Estate',
    address: {
      street: '123 Business Avenue',
      city: 'Paris',
      postalCode: '75001',
      country: 'France',
    },
    contact: {
      email: 'contact@a2g.com',
      phone: '+33 1 23 45 67 89',
      website: 'www.a2g.com',
    },
  },
  notifications: {
    email: {
      enabled: true,
      rentReminders: true,
      leaseExpirations: true,
      maintenanceAlerts: true,
      documentUpdates: true,
    },
    system: {
      enabled: true,
      desktop: true,
      sound: true,
    },
  },
  system: {
    language: 'en',
    dateFormat: 'DD/MM/YYYY',
    currency: 'EUR',
    timezone: 'Europe/Paris',
  },
  setCompanySettings: () => {},
  setNotificationSettings: () => {},
  setSystemSettings: () => {},
};

export const useSettingsStore = create<SettingsState>((set) => ({
  ...defaultSettings,
  setCompanySettings: (settings) =>
    set((state) => ({
      company: { ...state.company, ...settings },
    })),
  setNotificationSettings: (settings) =>
    set((state) => ({
      notifications: { ...state.notifications, ...settings },
    })),
  setSystemSettings: (settings) =>
    set((state) => ({
      system: { ...state.system, ...settings },
    })),
}));