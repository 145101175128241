import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { TenantCard } from '../components/tenants/TenantCard';
import { TenantFilters } from '../components/tenants/TenantFilters';
import { useTenantStore } from '../store/useTenantStore';
import type { Tenant } from '../types';

// Mock data for initial development
const mockTenants: Tenant[] = [
  {
    id: '1',
    firstName: 'Jean',
    lastName: 'Dupont',
    email: 'jean.dupont@email.com',
    phone: '+33 6 12 34 56 78',
    leaseStart: new Date('2024-01-01'),
    leaseEnd: new Date('2024-12-31'),
    propertyId: '1',
  },
  {
    id: '2',
    firstName: 'Marie',
    lastName: 'Laurent',
    email: 'marie.laurent@email.com',
    phone: '+33 6 98 76 54 32',
    leaseStart: new Date('2023-06-01'),
    leaseEnd: new Date('2024-05-31'),
    propertyId: '2',
  },
];

export function Tenants() {
  const { filteredTenants, setTenants, setFilters } = useTenantStore();
  const [searchTerm, setSearchTerm] = useState('');

  // Initialize with mock data
  React.useEffect(() => {
    setTenants(mockTenants);
  }, [setTenants]);

  const handleTenantClick = (tenant: Tenant) => {
    // Handle tenant selection
    console.log('Selected tenant:', tenant);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Tenants</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <Plus className="h-5 w-5 mr-2" />
          Add Tenant
        </button>
      </div>

      <div className="flex gap-6">
        <div className="w-64 flex-shrink-0">
          <TenantFilters onFilterChange={setFilters} />
        </div>

        <div className="flex-1 space-y-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search tenants..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredTenants.map((tenant) => (
              <TenantCard
                key={tenant.id}
                tenant={tenant}
                onClick={handleTenantClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}