import React from 'react';
import { Bell } from 'lucide-react';
import { useSettingsStore } from '../../store/useSettingsStore';

export function NotificationSettingsForm() {
  const { notifications, setNotificationSettings } = useSettingsStore();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNotificationSettings({
      email: { ...notifications.email, [name]: checked },
    });
  };

  const handleSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNotificationSettings({
      system: { ...notifications.system, [name]: checked },
    });
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Bell className="h-6 w-6 text-blue-600" />
        <h2 className="text-lg font-medium text-gray-900">Notification Preferences</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-4">Email Notifications</h3>
          <div className="space-y-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="enabled"
                checked={notifications.email.enabled}
                onChange={handleEmailChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">Enable email notifications</span>
            </label>

            <div className="ml-6 space-y-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="rentReminders"
                  checked={notifications.email.rentReminders}
                  onChange={handleEmailChange}
                  disabled={!notifications.email.enabled}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Rent payment reminders</span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="leaseExpirations"
                  checked={notifications.email.leaseExpirations}
                  onChange={handleEmailChange}
                  disabled={!notifications.email.enabled}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Lease expiration alerts</span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="maintenanceAlerts"
                  checked={notifications.email.maintenanceAlerts}
                  onChange={handleEmailChange}
                  disabled={!notifications.email.enabled}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Maintenance requests</span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="documentUpdates"
                  checked={notifications.email.documentUpdates}
                  onChange={handleEmailChange}
                  disabled={!notifications.email.enabled}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Document updates</span>
              </label>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-4">System Notifications</h3>
          <div className="space-y-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="enabled"
                checked={notifications.system.enabled}
                onChange={handleSystemChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">Enable system notifications</span>
            </label>

            <div className="ml-6 space-y-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="desktop"
                  checked={notifications.system.desktop}
                  onChange={handleSystemChange}
                  disabled={!notifications.system.enabled}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Desktop notifications</span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="sound"
                  checked={notifications.system.sound}
                  onChange={handleSystemChange}
                  disabled={!notifications.system.enabled}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Sound notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}