import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft, Save, Camera, UserCheck, Printer, Building2, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useInspectionStore } from '../../store/useInspectionStore';
import { usePropertyStore } from '../../store/usePropertyStore';
import { useTenantStore } from '../../store/useTenantStore';
import { RoomInspection } from '../../components/inspections/RoomInspection';
import { SignatureCanvas } from '../../components/inspections/SignatureCanvas';
import type { RoomElement } from '../../types/inspection';

const mockRooms = [
  {
    id: '1',
    name: 'Salon',
    elements: [
      {
        id: '1',
        category: 'walls',
        name: 'Murs',
        condition: 'good',
        photos: [],
        comments: '',
      },
      {
        id: '2',
        category: 'floor',
        name: 'Sol',
        condition: 'good',
        photos: [],
        comments: '',
      },
    ],
  },
  {
    id: '2',
    name: 'Cuisine',
    elements: [
      {
        id: '3',
        category: 'plumbing',
        name: 'Robinetterie',
        condition: 'good',
        photos: [],
        comments: '',
      },
    ],
  },
] as const;

export function InspectionForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isEntry = location.pathname.includes('/entry');
  const [showSignature, setShowSignature] = React.useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = React.useState<string>('');
  const [selectedTenantId, setSelectedTenantId] = React.useState<string>('');
  
  const { currentInspection, createInspection, updateInspection, sign, generateReport } = useInspectionStore();
  const { properties } = usePropertyStore();
  const { tenants } = useTenantStore();

  React.useEffect(() => {
    if (selectedPropertyId && selectedTenantId && !currentInspection) {
      createInspection({
        type: isEntry ? 'entry' : 'exit',
        propertyId: selectedPropertyId,
        tenantId: selectedTenantId,
        rooms: mockRooms,
      });
    }
  }, [currentInspection, isEntry, createInspection, selectedPropertyId, selectedTenantId]);

  const handleElementUpdate = (roomId: string, elementId: string, updates: Partial<RoomElement>) => {
    if (!currentInspection) return;

    const updatedRooms = currentInspection.rooms.map(room => {
      if (room.id !== roomId) return room;

      return {
        ...room,
        elements: room.elements.map(element =>
          element.id === elementId ? { ...element, ...updates } : element
        ),
      };
    });

    updateInspection(currentInspection.id, { rooms: updatedRooms });
  };

  const handleSignature = (signature: string) => {
    if (!currentInspection) return;
    sign(currentInspection.id, signature, 'tenant');
    setShowSignature(false);
  };

  const handlePrint = async () => {
    if (!currentInspection) return;
    try {
      const report = await generateReport(currentInspection.id);
      // Ouvrir le PDF dans un nouvel onglet
      window.open(report.url, '_blank');
    } catch (error) {
      console.error('Erreur lors de la génération du rapport:', error);
    }
  };

  if (!selectedPropertyId || !selectedTenantId) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-600"
          >
            <ChevronLeft className="h-5 w-5 mr-1" />
            Retour
          </button>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-6">
            Sélectionner la propriété et le locataire
          </h2>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                <div className="flex items-center gap-2">
                  <Building2 className="h-4 w-4" />
                  Propriété
                </div>
              </label>
              <select
                value={selectedPropertyId}
                onChange={(e) => setSelectedPropertyId(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Sélectionner une propriété</option>
                {properties.map((property) => (
                  <option key={property.id} value={property.id}>
                    {property.address.street}, {property.address.city}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                <div className="flex items-center gap-2">
                  <User className="h-4 w-4" />
                  Locataire
                </div>
              </label>
              <select
                value={selectedTenantId}
                onChange={(e) => setSelectedTenantId(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Sélectionner un locataire</option>
                {tenants.map((tenant) => (
                  <option key={tenant.id} value={tenant.id}>
                    {tenant.firstName} {tenant.lastName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!currentInspection) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-600"
        >
          <ChevronLeft className="h-5 w-5 mr-1" />
          Retour
        </button>

        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrint}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Printer className="h-4 w-4 mr-2" />
            Imprimer
          </button>
          <button
            onClick={() => setShowSignature(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <UserCheck className="h-4 w-4 mr-2" />
            Signer
          </button>
          <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700">
            <Save className="h-4 w-4 mr-2" />
            Enregistrer
          </button>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-6">
          État des lieux {isEntry ? "d'entrée" : "de sortie"}
        </h2>

        <div className="space-y-8">
          {currentInspection.rooms.map((room) => (
            <RoomInspection
              key={room.id}
              room={room}
              onUpdate={(elementId, updates) => handleElementUpdate(room.id, elementId, updates)}
            />
          ))}
        </div>
      </div>

      {showSignature && (
        <SignatureCanvas
          onSave={handleSignature}
          onCancel={() => setShowSignature(false)}
        />
      )}
    </div>
  );
}