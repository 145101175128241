import React from 'react';
import { Plus, Filter } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ContractList } from '../components/contracts/ContractList';
import { ContractForm } from '../components/contracts/ContractForm';
import { KeyDatesList } from '../components/contracts/KeyDatesList';
import { useContractStore } from '../store/useContractStore';
import type { Contract, KeyDate } from '../types/contract';

export function Contracts() {
  const { t } = useTranslation();
  const { setFilters, addContract, updateKeyDate } = useContractStore();
  const [showForm, setShowForm] = React.useState(false);
  const [selectedContract, setSelectedContract] = React.useState<Contract | null>(null);

  const handleContractSubmit = (contractData: Partial<Contract>) => {
    addContract(contractData as Contract);
    setShowForm(false);
  };

  const handleNotify = (keyDate: KeyDate) => {
    if (selectedContract) {
      updateKeyDate(selectedContract.id, keyDate.id, {
        notified: true,
        notifiedAt: new Date(),
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Contrats</h1>
        <button
          onClick={() => setShowForm(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nouveau contrat
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3">
          <ContractList
            onContractClick={setSelectedContract}
          />
        </div>

        <div className="space-y-6">
          {showForm ? (
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h2 className="text-lg font-medium text-gray-900 mb-4">
                {selectedContract ? 'Modifier le contrat' : 'Nouveau contrat'}
              </h2>
              <ContractForm
                onSubmit={handleContractSubmit}
                initialData={selectedContract || undefined}
              />
            </div>
          ) : selectedContract ? (
            <KeyDatesList
              keyDates={selectedContract.keyDates}
              onNotify={handleNotify}
            />
          ) : null}

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex items-center gap-2 mb-4">
              <Filter className="h-5 w-5 text-gray-400" />
              <h3 className="text-lg font-medium text-gray-900">Filtres</h3>
            </div>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Type
                </label>
                <select
                  onChange={(e) => setFilters({ type: e.target.value as any })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Tous</option>
                  <option value="lease">Bail</option>
                  <option value="amendment">Avenant</option>
                  <option value="termination">Résiliation</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Statut
                </label>
                <select
                  onChange={(e) => setFilters({ status: e.target.value as any })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Tous</option>
                  <option value="draft">Brouillon</option>
                  <option value="pending">En attente</option>
                  <option value="active">Actif</option>
                  <option value="expired">Expiré</option>
                  <option value="terminated">Résilié</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}