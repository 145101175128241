import React, { useRef, useState } from 'react';
import { X, Check } from 'lucide-react';

interface SignatureCanvasProps {
  onSave: (signature: string) => void;
  onCancel: () => void;
}

export function SignatureCanvas({ onSave, onCancel }: SignatureCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastPoint, setLastPoint] = useState<{ x: number; y: number } | null>(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    context.strokeStyle = '#000000';
    context.lineWidth = 2;
    context.lineCap = 'round';
    context.lineJoin = 'round';
  }, []);

  const getCoordinates = (event: React.TouchEvent | React.MouseEvent) => {
    const canvas = canvasRef.current;
    if (!canvas) return null;

    const rect = canvas.getBoundingClientRect();
    const x = ('touches' in event) 
      ? event.touches[0].clientX - rect.left
      : event.clientX - rect.left;
    const y = ('touches' in event)
      ? event.touches[0].clientY - rect.top
      : event.clientY - rect.top;

    return { x, y };
  };

  const startDrawing = (event: React.TouchEvent | React.MouseEvent) => {
    event.preventDefault();
    const coordinates = getCoordinates(event);
    if (!coordinates) return;

    setIsDrawing(true);
    setLastPoint(coordinates);
  };

  const draw = (event: React.TouchEvent | React.MouseEvent) => {
    event.preventDefault();
    if (!isDrawing || !lastPoint) return;

    const coordinates = getCoordinates(event);
    if (!coordinates) return;

    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (!canvas || !context) return;

    context.beginPath();
    context.moveTo(lastPoint.x, lastPoint.y);
    context.lineTo(coordinates.x, coordinates.y);
    context.stroke();

    setLastPoint(coordinates);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    setLastPoint(null);
  };

  const clear = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const save = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const signature = canvas.toDataURL('image/png');
    onSave(signature);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Signature</h3>
          <div className="space-x-2">
            <button
              onClick={clear}
              className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
            >
              Effacer
            </button>
            <button
              onClick={onCancel}
              className="p-2 text-gray-600 hover:text-gray-900"
            >
              <X className="h-5 w-5" />
            </button>
            <button
              onClick={save}
              className="p-2 text-blue-600 hover:text-blue-700"
            >
              <Check className="h-5 w-5" />
            </button>
          </div>
        </div>

        <canvas
          ref={canvasRef}
          width={500}
          height={200}
          className="border border-gray-300 rounded-lg touch-none"
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        />
      </div>
    </div>
  );
}