import { create } from 'zustand';
import type { Inspection, InspectionTemplate, Photo, Annotation } from '../types/inspection';

interface InspectionState {
  inspections: Inspection[];
  templates: InspectionTemplate[];
  currentInspection: Inspection | null;
  setInspections: (inspections: Inspection[]) => void;
  setTemplates: (templates: InspectionTemplate[]) => void;
  setCurrentInspection: (inspection: Inspection | null) => void;
  createInspection: (data: Partial<Inspection>) => void;
  updateInspection: (id: string, data: Partial<Inspection>) => void;
  addPhoto: (roomId: string, elementId: string, photo: Photo) => void;
  addAnnotation: (photoId: string, annotation: Annotation) => void;
  compareWithEntry: (exitInspectionId: string, entryInspectionId: string) => void;
  generateReport: (inspectionId: string) => Promise<Document>;
  sign: (inspectionId: string, signature: string, role: 'tenant' | 'landlord') => void;
}

export const useInspectionStore = create<InspectionState>((set, get) => ({
  inspections: [],
  templates: [],
  currentInspection: null,

  setInspections: (inspections) => set({ inspections }),
  setTemplates: (templates) => set({ templates }),
  setCurrentInspection: (inspection) => set({ currentInspection: inspection }),

  createInspection: (data) => {
    const newInspection: Inspection = {
      id: crypto.randomUUID(),
      type: data.type || 'entry',
      propertyId: data.propertyId!,
      tenantId: data.tenantId!,
      date: new Date(),
      status: 'draft',
      rooms: data.rooms || [],
      signatures: {},
      photos: [],
      comments: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      documents: [],
    };

    set((state) => ({
      inspections: [...state.inspections, newInspection],
      currentInspection: newInspection,
    }));
  },

  updateInspection: (id, data) => {
    set((state) => ({
      inspections: state.inspections.map((inspection) =>
        inspection.id === id
          ? { ...inspection, ...data, updatedAt: new Date() }
          : inspection
      ),
      currentInspection:
        state.currentInspection?.id === id
          ? { ...state.currentInspection, ...data, updatedAt: new Date() }
          : state.currentInspection,
    }));
  },

  addPhoto: (roomId, elementId, photo) => {
    set((state) => {
      if (!state.currentInspection) return state;

      const updatedRooms = state.currentInspection.rooms.map((room) => {
        if (room.id !== roomId) return room;

        const updatedElements = room.elements.map((element) => {
          if (element.id !== elementId) return element;
          return {
            ...element,
            photos: [...element.photos, photo],
          };
        });

        return { ...room, elements: updatedElements };
      });

      const updatedInspection = {
        ...state.currentInspection,
        rooms: updatedRooms,
        updatedAt: new Date(),
      };

      return {
        currentInspection: updatedInspection,
        inspections: state.inspections.map((inspection) =>
          inspection.id === updatedInspection.id ? updatedInspection : inspection
        ),
      };
    });
  },

  addAnnotation: (photoId, annotation) => {
    set((state) => {
      if (!state.currentInspection) return state;

      const updatedRooms = state.currentInspection.rooms.map((room) => ({
        ...room,
        elements: room.elements.map((element) => ({
          ...element,
          photos: element.photos.map((photo) =>
            photo.id === photoId
              ? {
                  ...photo,
                  annotations: [...photo.annotations, annotation],
                }
              : photo
          ),
        })),
      }));

      const updatedInspection = {
        ...state.currentInspection,
        rooms: updatedRooms,
        updatedAt: new Date(),
      };

      return {
        currentInspection: updatedInspection,
        inspections: state.inspections.map((inspection) =>
          inspection.id === updatedInspection.id ? updatedInspection : inspection
        ),
      };
    });
  },

  compareWithEntry: (exitInspectionId, entryInspectionId) => {
    const exitInspection = get().inspections.find((i) => i.id === exitInspectionId);
    const entryInspection = get().inspections.find((i) => i.id === entryInspectionId);

    if (!exitInspection || !entryInspection) return;

    const updatedRooms = exitInspection.rooms.map((exitRoom) => {
      const entryRoom = entryInspection.rooms.find((r) => r.name === exitRoom.name);
      if (!entryRoom) return exitRoom;

      return {
        ...exitRoom,
        elements: exitRoom.elements.map((exitElement) => {
          const entryElement = entryRoom.elements.find((e) => e.name === exitElement.name);
          if (!entryElement) return exitElement;

          return {
            ...exitElement,
            comparison: {
              previousCondition: entryElement.condition,
              differences:
                exitElement.condition !== entryElement.condition
                  ? [`État modifié de ${entryElement.condition} à ${exitElement.condition}`]
                  : [],
            },
          };
        }),
      };
    });

    set((state) => ({
      inspections: state.inspections.map((inspection) =>
        inspection.id === exitInspectionId
          ? {
              ...inspection,
              rooms: updatedRooms,
              comparisonId: entryInspectionId,
              updatedAt: new Date(),
            }
          : inspection
      ),
    }));
  },

  generateReport: async (inspectionId) => {
    const inspection = get().inspections.find((i) => i.id === inspectionId);
    if (!inspection) throw new Error('Inspection not found');

    // Logique de génération du rapport PDF à implémenter
    return {
      id: crypto.randomUUID(),
      title: `État des lieux ${inspection.type === 'entry' ? "d'entrée" : "de sortie"}`,
      type: 'report',
      category: 'property',
      status: 'signed',
      createdAt: new Date(),
      updatedAt: new Date(),
      size: 0,
      relatedTo: {
        type: 'property',
        id: inspection.propertyId,
      },
    };
  },

  sign: (inspectionId, signature, role) => {
    set((state) => ({
      inspections: state.inspections.map((inspection) =>
        inspection.id === inspectionId
          ? {
              ...inspection,
              signatures: {
                ...inspection.signatures,
                [role]: {
                  id: crypto.randomUUID(),
                  signedBy: role === 'tenant' ? inspection.tenantId : 'landlord',
                  signedAt: new Date(),
                  signature,
                },
              },
              status: 'signed',
              updatedAt: new Date(),
            }
          : inspection
      ),
    }));
  },
}));