import React from 'react';
import { Filter } from 'lucide-react';
import { usePropertyStore } from '../../store/usePropertyStore';

interface TenantFiltersProps {
  onFilterChange: (filters: any) => void;
}

export function TenantFilters({ onFilterChange }: TenantFiltersProps) {
  const properties = usePropertyStore((state) => state.properties);

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <div className="flex items-center gap-2 text-gray-700 mb-4">
        <Filter className="w-5 h-5" />
        <h3 className="font-medium">Filters</h3>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <select 
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            onChange={(e) => onFilterChange({ status: e.target.value })}
          >
            <option value="">All Status</option>
            <option value="active">Active</option>
            <option value="expired">Expired</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Property
          </label>
          <select 
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            onChange={(e) => onFilterChange({ propertyId: e.target.value })}
          >
            <option value="">All Properties</option>
            {properties.map((property) => (
              <option key={property.id} value={property.id}>
                {property.address.street}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}