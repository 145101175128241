import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { PropertyCard } from '../components/properties/PropertyCard';
import { PropertyFilters } from '../components/properties/PropertyFilters';
import { usePropertyStore } from '../store/usePropertyStore';
import type { Property } from '../types';

// Données de test pour le développement initial
const mockProperties: Property[] = [
  {
    id: '1',
    type: 'apartment',
    status: 'available',
    address: {
      street: '123 Rue de la Paix',
      city: 'Paris',
      postalCode: '75001',
      country: 'France',
    },
    features: {
      size: 85,
      rooms: 3,
      bathrooms: 2,
      parking: true,
    },
    price: 450000,
  },
];

export function Properties() {
  const { t } = useTranslation();
  const { filteredProperties, setProperties, setFilters } = usePropertyStore();
  const [searchTerm, setSearchTerm] = useState('');

  React.useEffect(() => {
    setProperties(mockProperties);
  }, [setProperties]);

  const handlePropertyClick = (property: Property) => {
    console.log('Propriété sélectionnée:', property);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">{t('properties.title')}</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <Plus className="h-5 w-5 mr-2" />
          {t('properties.addProperty')}
        </button>
      </div>

      <div className="flex gap-6">
        <div className="w-64 flex-shrink-0">
          <PropertyFilters onFilterChange={setFilters} />
        </div>

        <div className="flex-1 space-y-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder={t('properties.searchProperties')}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredProperties.map((property) => (
              <PropertyCard
                key={property.id}
                property={property}
                onClick={handlePropertyClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}