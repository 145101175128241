import React from 'react';
import { Plus, Wrench } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { MaintenanceRequestList } from '../components/maintenance/MaintenanceRequestList';
import { MaintenanceScheduleCalendar } from '../components/maintenance/MaintenanceScheduleCalendar';
import { MaintenanceFilters } from '../components/maintenance/MaintenanceFilters';
import { useMaintenanceStore } from '../store/useMaintenanceStore';
import type { MaintenanceRequest, MaintenanceSchedule } from '../types/maintenance';

const mockRequests: MaintenanceRequest[] = [
  {
    id: '1',
    reference: 'MDT-2403-001',
    propertyId: '1',
    tenantId: '1',
    title: 'Fuite robinet cuisine',
    description: 'Le robinet de la cuisine fuit constamment',
    priority: 'medium',
    status: 'new',
    category: 'plumbing',
    createdAt: new Date(),
    updatedAt: new Date(),
    photos: [],
    documents: [],
    interventions: [],
  },
];

const mockSchedules: MaintenanceSchedule[] = [
  {
    id: '1',
    propertyId: '1',
    type: 'recurring',
    title: 'Entretien chaudière',
    description: 'Entretien annuel de la chaudière',
    frequency: 'yearly',
    nextDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    estimatedDuration: 120,
    category: 'heating',
    status: 'pending',
  },
];

export function Maintenance() {
  const { t } = useTranslation();
  const { setRequests, setSchedules } = useMaintenanceStore();
  const [selectedRequest, setSelectedRequest] = React.useState<MaintenanceRequest | null>(null);

  React.useEffect(() => {
    setRequests(mockRequests);
    setSchedules(mockSchedules);
  }, [setRequests, setSchedules]);

  const handleRequestClick = (request: MaintenanceRequest) => {
    setSelectedRequest(request);
  };

  const handleScheduleClick = (schedule: MaintenanceSchedule) => {
    console.log('Schedule clicked:', schedule);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <Wrench className="h-8 w-8 text-blue-600" />
          <h1 className="text-2xl font-semibold text-gray-900">
            {t('maintenance.title')}
          </h1>
        </div>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <Plus className="h-5 w-5 mr-2" />
          {t('maintenance.newRequest')}
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3 space-y-6">
          <MaintenanceRequestList onRequestClick={handleRequestClick} />
          <MaintenanceScheduleCalendar onScheduleClick={handleScheduleClick} />
        </div>

        <div>
          <MaintenanceFilters />
        </div>
      </div>
    </div>
  );
}