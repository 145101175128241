import React from 'react';
import { Building2 } from 'lucide-react';
import { useSettingsStore } from '../../store/useSettingsStore';

export function CompanySettingsForm() {
  const { company, setCompanySettings } = useSettingsStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');
    
    if (section === 'address' || section === 'contact') {
      setCompanySettings({
        [section]: { ...company[section], [field]: value },
      });
    } else {
      setCompanySettings({ [name]: value });
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Building2 className="h-6 w-6 text-blue-600" />
        <h2 className="text-lg font-medium text-gray-900">Company Information</h2>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Company Name</label>
          <input
            type="text"
            name="name"
            value={company.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">Address</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-500">Street</label>
                <input
                  type="text"
                  name="address.street"
                  value={company.address.street}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500">City</label>
                <input
                  type="text"
                  name="address.city"
                  value={company.address.city}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500">Postal Code</label>
                <input
                  type="text"
                  name="address.postalCode"
                  value={company.address.postalCode}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500">Country</label>
                <input
                  type="text"
                  name="address.country"
                  value={company.address.country}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">Contact Information</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-500">Email</label>
                <input
                  type="email"
                  name="contact.email"
                  value={company.contact.email}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500">Phone</label>
                <input
                  type="tel"
                  name="contact.phone"
                  value={company.contact.phone}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500">Website</label>
                <input
                  type="url"
                  name="contact.website"
                  value={company.contact.website}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}