import { create } from 'zustand';
import type { Building, CoOwner, GeneralAssembly, BuildingExpense } from '../types/condo';

interface BuildingState {
  buildings: Building[];
  coOwners: CoOwner[];
  assemblies: GeneralAssembly[];
  expenses: BuildingExpense[];
  setBuildings: (buildings: Building[]) => void;
  setCoOwners: (coOwners: CoOwner[]) => void;
  setAssemblies: (assemblies: GeneralAssembly[]) => void;
  setExpenses: (expenses: BuildingExpense[]) => void;
  addBuilding: (building: Building) => void;
  updateBuilding: (id: string, building: Partial<Building>) => void;
  addCoOwner: (coOwner: CoOwner) => void;
  updateCoOwner: (id: string, coOwner: Partial<CoOwner>) => void;
  addAssembly: (assembly: GeneralAssembly) => void;
  updateAssembly: (id: string, assembly: Partial<GeneralAssembly>) => void;
  addExpense: (expense: BuildingExpense) => void;
  updateExpense: (id: string, expense: Partial<BuildingExpense>) => void;
}

export const useBuildingStore = create<BuildingState>((set) => ({
  buildings: [],
  coOwners: [],
  assemblies: [],
  expenses: [],
  setBuildings: (buildings) => set({ buildings }),
  setCoOwners: (coOwners) => set({ coOwners }),
  setAssemblies: (assemblies) => set({ assemblies }),
  setExpenses: (expenses) => set({ expenses }),
  addBuilding: (building) =>
    set((state) => ({
      buildings: [...state.buildings, building],
    })),
  updateBuilding: (id, updates) =>
    set((state) => ({
      buildings: state.buildings.map((b) =>
        b.id === id ? { ...b, ...updates } : b
      ),
    })),
  addCoOwner: (coOwner) =>
    set((state) => ({
      coOwners: [...state.coOwners, coOwner],
    })),
  updateCoOwner: (id, updates) =>
    set((state) => ({
      coOwners: state.coOwners.map((c) =>
        c.id === id ? { ...c, ...updates } : c
      ),
    })),
  addAssembly: (assembly) =>
    set((state) => ({
      assemblies: [...state.assemblies, assembly],
    })),
  updateAssembly: (id, updates) =>
    set((state) => ({
      assemblies: state.assemblies.map((a) =>
        a.id === id ? { ...a, ...updates } : a
      ),
    })),
  addExpense: (expense) =>
    set((state) => ({
      expenses: [...state.expenses, expense],
    })),
  updateExpense: (id, updates) =>
    set((state) => ({
      expenses: state.expenses.map((e) =>
        e.id === id ? { ...e, ...updates } : e
      ),
    })),
}));