import React from 'react';
import { Plus, Calendar, Users } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useBuildingStore } from '../../store/useBuildingStore';
import { formatDateLocale } from '../../utils/date';

export function Assemblies() {
  const { t } = useTranslation();
  const { assemblies } = useBuildingStore();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">
          {t('condo.assemblies')}
        </h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <Plus className="h-5 w-5 mr-2" />
          {t('condo.addAssembly')}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {assemblies.map((assembly) => (
          <div key={assembly.id} className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  AG {assembly.type === 'extraordinary' ? 'Extraordinaire' : 'Ordinaire'}
                </h3>
                <p className="text-sm text-gray-500">
                  {formatDateLocale(assembly.date)}
                </p>
              </div>
              <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                assembly.status === 'completed' ? 'bg-green-100 text-green-800' :
                assembly.status === 'scheduled' ? 'bg-blue-100 text-blue-800' :
                assembly.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                'bg-yellow-100 text-yellow-800'
              }`}>
                {t(`condo.status.${assembly.status}`)}
              </span>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-sm text-gray-600">
                <Calendar className="w-4 h-4 mr-2" />
                <span>{assembly.agenda.length} points à l'ordre du jour</span>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <Users className="w-4 h-4 mr-2" />
                <span>{assembly.attendees.length} participants</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}