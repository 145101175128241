import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fr: {
    translation: {
      common: {
        search: 'Rechercher',
        filters: 'Filtres',
        all: 'Tous',
      },
      navigation: {
        dashboard: 'Tableau de bord',
        properties: 'Propriétés',
        tenants: 'Locataires',
        documents: 'Documents',
        reports: 'Rapports',
        settings: 'Paramètres',
        condo: 'Copropriété',
        maintenance: 'Maintenance',
        inspections: 'États des lieux'
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;