import React, { useRef, useState } from 'react';
import { Camera, X, Edit2, Check } from 'lucide-react';
import { useInspectionStore } from '../../store/useInspectionStore';
import type { Photo, Annotation } from '../../types/inspection';

interface InspectionCameraProps {
  roomId: string;
  elementId: string;
  onClose: () => void;
}

export function InspectionCamera({ roomId, elementId, onClose }: InspectionCameraProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showAnnotation, setShowAnnotation] = useState(false);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const { addPhoto } = useInspectionStore();

  React.useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          video: { facingMode: 'environment' } 
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error('Erreur d\'accès à la caméra:', err);
      }
    };

    startCamera();

    return () => {
      const stream = videoRef.current?.srcObject as MediaStream;
      stream?.getTracks().forEach(track => track.stop());
    };
  }, []);

  const takePhoto = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context?.drawImage(video, 0, 0, canvas.width, canvas.height);

    setShowAnnotation(true);
  };

  const handleAnnotation = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!showAnnotation) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;

    const newAnnotation: Annotation = {
      id: crypto.randomUUID(),
      type: 'circle',
      position: { x, y },
      content: 'Annotation',
      color: '#FF0000',
    };

    setAnnotations([...annotations, newAnnotation]);
  };

  const savePhoto = async () => {
    if (!canvasRef.current) return;

    const photo: Photo = {
      id: crypto.randomUUID(),
      url: canvasRef.current.toDataURL('image/jpeg'),
      thumbnail: canvasRef.current.toDataURL('image/jpeg', 0.1),
      annotations,
      takenAt: new Date(),
    };

    addPhoto(roomId, elementId, photo);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black z-50 flex flex-col">
      <div className="flex justify-between items-center p-4 bg-gray-900">
        <button
          onClick={onClose}
          className="text-white"
        >
          <X className="h-6 w-6" />
        </button>
        {showAnnotation ? (
          <button
            onClick={savePhoto}
            className="text-white"
          >
            <Check className="h-6 w-6" />
          </button>
        ) : (
          <button
            onClick={takePhoto}
            className="text-white"
          >
            <Camera className="h-6 w-6" />
          </button>
        )}
      </div>

      <div className="flex-1 relative">
        {!showAnnotation ? (
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className="w-full h-full object-cover"
          />
        ) : (
          <canvas
            ref={canvasRef}
            onClick={handleAnnotation}
            className="w-full h-full object-contain"
          />
        )}

        {showAnnotation && annotations.map((annotation) => (
          <div
            key={annotation.id}
            style={{
              position: 'absolute',
              left: `${annotation.position.x * 100}%`,
              top: `${annotation.position.y * 100}%`,
              transform: 'translate(-50%, -50%)',
            }}
            className="w-8 h-8 rounded-full border-2 border-red-500 flex items-center justify-center"
          >
            <Edit2 className="h-4 w-4 text-red-500" />
          </div>
        ))}
      </div>
    </div>
  );
}